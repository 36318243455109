import React from 'react';
import Header from './Header';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const CustomCarousel = ({ item }) => {

    return (
        <div className='mt-16' key={item?.id} >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barColor={item?.barColor} position={item?.titlePosition} />
            <div className='flex justify-between items-center' >
                <Carousel className='flex items-center'  autoPlay infiniteLoop interval={3000} stopOnHover showIndicators={false} showThumbs={false} showArrows showStatus={false}>
                    {item?.values?.map((url) => {
                        return (
                            <div>
                                <img src={url} alt="project1" />
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        </div>
    )
};

export default CustomCarousel;