import React, { useState } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import file from "../../Assets/LeinaResume.pdf";
import { useNavigate } from "react-router-dom";
import Icon from '../Icons';
import Button from '../Blocks/Button';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ResumePdf = () => {
    const navigate = useNavigate();
    const [pdfPages, setPdfPages] = useState();

    const downloadResume = () => {
        const link = document.createElement("a");
        link.href = file
        link.download = "Leina_Resume";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='resumeMain w-full'>
            <div  className='w-full flex justify-center p-2 resumeHead'>
                <div className='absolute top-5 left-8'>
                    <Icon className='cursor-pointer' iconType="backArrow" width="40px" height="40px" onClick={() => navigate("/") } />     
                </div>
                <Button className='mr-4 buttonPrimary' title="Download" handleClick={downloadResume} />
            </div>
            <div className='h-screen relative p-2' >
                <div className='flex justify-center h-full items-center overflow-hidden resumeDoc' >
                    <Document
                        className="overflow-auto h-[82vh] bg-neutral-0 max-h-[82vh] min-w-[80vw]"
                        file={file}
                        onLoadSuccess={({ numPages }) => {
                        setPdfPages(numPages);
                        }}
                        renderMode='canvas'
                    >
                        {Array(pdfPages)
                            .fill(null)
                            .map((_element, index) => (
                                <Page
                                    key={`page-${index + 1}`}
                                    pageNumber={index + 1}
                                    renderAnnotationLayer={false}
                                    scale={1}
                                    rotate={0}
                                    renderTextLayer={false}
                                >
                                </Page>
                            ))}
                    </Document>
                </div>
            </div>
        </div>
    )
};

export default ResumePdf;