import React, { useContext, createContext, useEffect, useState } from 'react';
import { app } from '../firebase';
import { collection, getDocs, doc, getDoc ,getFirestore } from "firebase/firestore"; 

const PortfolioContext = createContext()

export const PortfolioContextProvider = ({children}) => {
    const [dataLoader, setDataLoader] = useState(false)
    const [error, setError] = useState("")
    const [projects, setProjects] = useState([])
    const [aboutDetails, setAboutDetails] = useState()
    const [version, setVersion] = useState("VERSION_2");
    
    useEffect(() => {
        const getAboutDetails = async () => {
            const db = getFirestore(app);
            getDoc(doc(db, "homeAbout", "9UYb4lOdeLz8zx7MMqKP"))
            .then(result => {
                setAboutDetails({...result.data()})
                const data = {...result.data()}
                setVersion(data?.version)
                getProjects(data?.projectDb)
            })
            .catch(error => {
                setError(error)
                setDataLoader(false)
            })
        }

    const getProjects = async (projectDb) => {
        const db = getFirestore(app);
        getDocs(collection(db, projectDb))
        .then(result => {
            const allProjects = [];
            result.forEach((doc) => {
                
                allProjects.push(doc.data());
            });
            setProjects(allProjects);
            setDataLoader(false);
        })
        .catch(error => {
            setError(error)
            setDataLoader(false);
        })
    }
        setDataLoader(true)
        getAboutDetails();
    }, []);

    return (
        <PortfolioContext.Provider value={{ aboutDetails, error, dataLoader, projects, version }} >
            {children}
        </PortfolioContext.Provider>
    )
}

export const PortfolioData = () => {
    return useContext(PortfolioContext)
}