import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import helloImage from "../../Assets/hiImage.jpg";
import Button from '../Blocks/Button';
import { useNavigate } from "react-router-dom";
import Loader from '../Blocks/Loader';
import { PortfolioData } from '../context';

const Home = () => {
    const { aboutDetails, dataLoader, error } = PortfolioData();
    const [imageLoader, setImageLoader] = useState(true);
    const navigate = useNavigate();

    const handleImageLoader = () => {
        setImageLoader(false);
    }

    return (
        dataLoader ?  <Loader />
        :
        (error === "" ?
             <div className='flex'>
                <Carousel className='h-screen flex items-center w-3/5' autoPlay infiniteLoop interval={3000} stopOnHover showIndicators={false} showThumbs={false} showArrows={false} showStatus={false}>
                    {aboutDetails?.projectImages?.map((url) => {
                        return (
                            <div>
                                <img src={url} alt="project1" onLoad={handleImageLoader} />
                                {imageLoader && <Loader />}
                            </div>
                        )
                    })}
                </Carousel>
                
                <div className='w-2/5 mx-24 flex flex-col items-start justify-center'>
                    <div className='font-bold fontSize_header textColorBlack80'>N Leina Singha</div>
                    <div className='flex fontSize_text mt-4' >
                        
                        <div className='flex items-center textColorBlack80 font-medium'>
                            <img className='mr-2' style={{ width: "18px", height: "18px" }} src={helloImage} alt="hi" />
                            Hello, Leina here!!
                        </div>
                    </div>
                    <div className='fontSize_text mt-4 textColorBlack80 font-medium text-justify' >{aboutDetails?.about}</div>
                    <div className='mt-10 flex'>
                        <Button className='mr-4 buttonPrimary' title="My Work" handleClick={() => navigate("/main")} />
                        <Button className='mr-4 buttonSecondary' title="Resume" handleClick={() => navigate("/resume")} />
                    </div>
                </div>
            </div>
        :
            <div className='error_message'>Something Went wrong</div>
        )
    )
};

export default Home;