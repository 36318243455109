import React from 'react';
import SubHeading from './SubHeading';

const Colors = ({ item }) => {

    return (
        <div>
            <SubHeading item={{ title: item?.title, titleColor: item?.titleColor }} />
            <div className='my-12 flex relative' >
                {item?.values?.map((data, index) => {
                    if (data?.type === "SINGLECOLOR") {
                        return (
                            <div key={data?.id} className='h-52 w-52 rounded-full flex items-center justify-center relative fontSize-18 lineHeight-27 font-medium' style={{color: data?.valueColor, backgroundColor: data?.value, left: index !== 0 ? -(index)*32 + "px": "" }} >{data?.value}</div>
                        )
                    } else if (data?.type === "GRADIENTCOLOR") {
                        return (
                            <div key={data?.id} className='h-52 w-52 rounded-full flex flex-col items-center justify-center relative fontSize-18 lineHeight-27 font-medium' style={{color: data?.valueColor, background: "linear-gradient(" + data?.values[0] + "," + data?.values[1] + ")", left: index !== 0 ? -(index)*32 + "px": "" }} >
                                <div>{data?.values[0]}</div>
                                <div>{data?.values[1]}</div>
                            </div>
                        )
                    } else {
                        return (
                            <></>
                        )
                    }
                })}
            </div>
        </div>
    )
};

export default Colors;