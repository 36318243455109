import React from 'react';
import Icon from './Icons';

const Banner = ({ item }) => {

    return (
        <div key={item?.id} className='my-10 px-20 py-11 flex justify-evenly' style={{ backgroundColor: item?.bgColor, borderRadius: item?.borderRadius }} >
            {item?.values?.map((data) => {
                return (
                    <div key={data?.id} className='flex flex-col items-center' style={{ color: data?.textColor }} >
                        <Icon className='mb-4' iconType={data?.iconType} width={data?.iconWidth} height={data?.iconHeight} fill={data?.iconColor} />
                        <div className='mb-2 bannerTitle' >{data?.title}</div>
                        <div>
                            {data?.values?.map((text) => {
                                return (
                                    <div className='bannerText mt-2' >{text}</div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
};

export default Banner;