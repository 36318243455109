import React from 'react';

const AnimationTriangular = () => {

  const getInnerElement = () => {
    return (
        <div class="framer-oS6ri framer-v-1gh5l8w" style={{ display:"contents"}} tabindex="0">
          <div class="framer-1gh5l8w" data-framer-name="Variant 1" style={{ height: "100%", width: "100%", opacity: "1" }}>
            <div data-framer-component-type="SVG" style={{ imageRendering: "pixelated", flexShrink: "0", opacity: "1" }} class="framer-1w7zx2b">
              <div class="svgContainer" style={{ width:"100%", height:"100%", aspectRatio:"inherit"}}>
                <svg width="683" height="637" viewBox="0 0 683 637" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.5" d="M-0.477478 443.5L255 1L510.478 443.5H-0.477478Z" stroke="#9A9A9A"/>
                  <path opacity="0.5" d="M171.523 443.5L427 1L682.478 443.5H171.523Z" stroke="#9A9A9A"/>
                  <path opacity="0.5" d="M85.5225 591.5L341 149L596.478 591.5H85.5225Z" stroke="#9A9A9A"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
    );
  }

  return (
    <div className='animation_main' >
      <div class="framer-1-container" data-framer-appear-id="1ih825l" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(1.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-2-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(2.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-3-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(3.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-4-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(4.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-5-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(5.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-6-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(6.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-7-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(7.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-8-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(8.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-9-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(9.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-10-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(10.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-11-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(11.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-12-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(12.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-13-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(13.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
      <div class="framer-14-container" data-framer-appear-id="1v5858s" style={{ opacity: "1", transform: "perspective(1200px) translateX(0px) translateY(0px) scale(1) rotate(14.1178deg) rotateX(0deg) rotateY(0deg) translateZ(0px)" }}>
        {getInnerElement()}
      </div>
    </div>
   )
};

export default AnimationTriangular;
