import React from 'react';
import Header from './Header';


const TargetAudience = ({ item }) => {

    return (
        <div className='mt-16' key={item?.id} >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barColor={item?.barColor} />
            <div className='flex flex-col mt-20' >
                {item?.values?.map((data, index) => {
                    return (
                        <div key={data?.id} className={`mb-10 flex items-center ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"}`} >
                            <div 
                                style={{ 
                                    color: data?.titleColor, 
                                    width: data?.width, 
                                    height: data?.height,
                                    backgroundColor: data?.bgColor,
                                    borderColor: data?.borderColor
                                }}
                                className='flex border-2 items-center px-12 justify-center rounded-full border-dashed text-center targetAudienceCircle'
                            >
                                {data?.title}
                            </div>
                            <ul style={{color: data?.valueColor, listStyleType: "disc" }} className={`targetAudienceText w-1/3 ${index % 2 === 0 ? "ml-20" : "mr-20"}`}  >
                                {data?.values?.map((text, i) => {
                                    return (
                                        <li key={i} >{text}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default TargetAudience;