import React from 'react';
import TitleValue from './TitleValue';
import StepBanner from './StepBanner';
import TitleValues from './TitleValues';
import Header from './Header';
import SubHeading from './SubHeading';
import Colors from './Colors';
import KeyValue from './KeyValue';
import Banner from './Banner';
import { CONTENT_TYPE } from './constants';
import TargetAudience from './TargetAudience';
import CompetitiveAnalysis from './CompetitiveAnalysis';
import ParaPoints from './ParaPoints';
import BannerPoints from './BannerPoints';
import CustomCarousel from './CustomCarousel';


const Section = ({ data }) => {

    return (
        <div key={data?.id} style={{ backgroundColor: data?.bgColor }} className='py-16 px-40' >
            {data?.values?.map((item) => {
                if (item?.type === CONTENT_TYPE.TITLE_VALUE) {
                    return (
                        <TitleValue item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.BANNER) {
                    return (
                        <Banner item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.STEP_BANNER) {
                    return (
                        <StepBanner item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.TITLE_VALUES) {
                    return (
                        <TitleValues item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.IMAGE) {
                    return (
                        <div key={item.id} className='pt-16' >
                            {item?.title && <Header title={item?.title} titleColor={item?.titleColor} bar={true} /> }
                            <img style={{ height: item?.height }} src={item.value} alt="projectImage" />
                        </div>
                    )
                } else if (item?.type === CONTENT_TYPE.HEADING) {
                    return  (
                        <div className='pt-16' >
                            <Header title={item?.title} titleColor={item?.titleColor} bar={item?.title !== ""} barColor={item?.barColor} />
                        </div>
                    )
                } else if (item?.type === CONTENT_TYPE.SUBHEADING) {
                    return (
                        <SubHeading item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.COLORS) {
                    return (
                        <Colors item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.KEY_VALUE) {
                    return (
                        <KeyValue item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.TARGET_AUDIENCE) {
                    return (
                        <TargetAudience item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.COMPETITIVE_ANALYSIS) {
                    return (
                        <CompetitiveAnalysis item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.PARA_POINTS) {
                    return (
                        <ParaPoints item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.BANNER_POINTS) {
                    return (
                        <BannerPoints item={item} />
                    )
                } else if (item?.type === CONTENT_TYPE.CAROUSEL ) {
                    return (
                        <CustomCarousel item={item} />
                    )
                } else {
                    return (
                        <div></div>
                    )
                }
            })}
        </div>
    )
};

export default Section;