import { Route, Routes, MemoryRouter } from 'react-router-dom';
import Home from "./Home";
import Dashboard from './Dashboard';
import Project from './Project';
import ResumePdf from './ResumePdf';


const MainV1 = () => {

  return (
    <div className="mainV1">
        <MemoryRouter 
          initialEntries={["/"]}
          initialIndex={1}
        >
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/main" element={<Dashboard  />} />
              <Route exact path="/resume" element={<ResumePdf />} />
              <Route exact path="/project/:id" element={<Project />} />
          </Routes> 
        </MemoryRouter>
    </div>
  );
}

export default MainV1;
