import React, { useEffect, useState } from 'react';
import Icon from '../Icons';
import { PortfolioData } from '../context';
import { useNavigate } from "react-router-dom";
import Loader from '../Blocks/Loader';
import Footer from '../Blocks/Footer';


const Dashboard = () => {
    const navigate = useNavigate();
    const { projects } = PortfolioData();
    const [page, setPage] = useState("work");
    const [barStyle, setBarStyle] = useState({});
    const [hoverInfo, setHoverInfo] = useState({ index: "", style: {}});
    const [imageLoader, setImageLoader] = useState(true);

    useEffect(() => {
        if (page !== "") {
            let e1 = document.getElementById("work_button");
            if ( page === "work") {
                e1 = document.getElementById("work_button");
            } else if ( page === "aboutMe") {
                e1 = document.getElementById("aboutMe_button");
            } else if ( page === "resume") {
                e1 = document.getElementById("resume_button");
            }
            let x1 = e1 && e1.getBoundingClientRect().x;
            let width = e1 && e1.getBoundingClientRect().width;
            const style = {
                width: x1 - 192 + width +  "px",
            }
            setBarStyle(style);
        }
    }, [page])

    const handleHoverStyle = (color, index) => {
        setHoverInfo({index: index, style: { color: color}})
    }

    const handleImageLoader = () => {
        setImageLoader(false)
    }

    const handlePageContent = () => {
        if (page === "work") {
            return (
                projects.map((project, index) => {
                    return (
                        <div className='flex justify-between items-center my-24' >
                            <div className='text-left w-2/5' >
                                <div className='flex items-center' >
                                    <div className='cursor-pointer' onClick={() => navigate("/project/"+ project.projectPortfolioId)} onMouseEnter={() => handleHoverStyle(project.barColor, index)} onMouseLeave={() => handleHoverStyle("", "")} >
                                        <div className='projectTitleDashboard mb-3' style={hoverInfo.index === index ? hoverInfo.style : {}} >{project.title} </div>
                                        <div className='projectInfoDashboard' style={hoverInfo.index === index ? hoverInfo.style : {}} >{project.about} </div>
                                    </div>
                                    <div className='ml-4 horizontalProjectBar' style={{ backgroundColor: project?.barColor || "" }} ></div>
                                </div>
                                <div className='mt-8 projectDurationDashboard' >{project.duration} </div>
                            </div>
                            <div className='w-3/5 ml-24'>
                                <img src={project?.imageUrl} alt="projectImage" onLoad={handleImageLoader} />
                                {imageLoader && <Loader />}
                            </div>
                        </div>
                    )
                })
            )
        }
        if (page === "aboutMe") {
            return (
                <div>about me</div>
            )
        }
        if (page === "resume") {
            return (
                <div>resume</div>
            )
        }
    }

    return (
        <>
            <div className='mt-10 mx-40' >
                <div className='flex justify-center items-center fontSize_subHeader textColorBlack80 font-semibold cursor-pointer' onClick={() => navigate("/")} >N Leina Singha</div>
                <div className='mt-5 flex items-center justify-center relative'>
                    <div className='absolute top-4 left-0' >
                        <Icon iconType="star" fill="#0968BD" />
                    </div>
                    <div className='flex justify-between font-semibold' >
                        <div id="work_button" onClick={() => setPage("work")} className={`${page !== "work" ? "cursor-pointer textColorBlack40" : "textColorBlack80"}`} >Work</div>
                        <div id="resume_button" onClick={() => navigate("/resume")} className={`ml-14 cursor-pointer textColorBlack40`} >Resume</div>
                    </div>
                </div>
                <div className="horizontalBar mt-1 ml-8" style={barStyle}></div>
                <div className='mt-16 flex flex-col-reverse' >
                    {handlePageContent()}
                </div>
            </div>
            <Footer className="lp_footer_container" version="1" />
        </>
    )
};

export default Dashboard;