import React from 'react';
import Header from './Header';


const StepBanner = ({item}) => {

    return (
        <div key={item?.id}>
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} />
            <div className='flex flex-col' >
                {item?.values?.map((banner, index) => {
                    return (
                        <div key={banner?.id} style={{ backgroundColor: item?.bgColor, borderRadius: item?.borderRadius}} className={`px-10 py-4 my-4 flex items-center w-2/3 ${index % 2 === 0 ? "self-start" : "self-end"}`} >
                            <div className='mr-6 stepBannerNumber px-8 flex items-center justify-center w-1/5' style={{ backgroundColor: item?.numberBgColor, color: item?.numberColor, borderRadius: "100%", height: "100px", width: "100px" }} >
                                {index + 1}
                            </div>
                            <div style={{ color: item?.valueColor }} className='stepBannerValue w-4/5' >{banner?.value}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default StepBanner;