import { Route, Routes, MemoryRouter } from 'react-router-dom';
import Home from "./Home";
import Project from '../Version1/Project';
import ResumePdf from '../Version1/ResumePdf';
import Footer from '../Blocks/Footer';
import Navbar from './Navbar';
import About from './About';


const MainV2 = () => {

  return (
    <div className="mainV2">
        <MemoryRouter 
          initialEntries={["/"]}
          initialIndex={1}
        >
          <Navbar />
          <Routes>
              <Route exact path="/" element={<Home  />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/resume" element={<ResumePdf />} />
              <Route exact path="/project/:id" element={<Project />} />
          </Routes> 
          <Footer className="footerV2" version="2" />
        </MemoryRouter>
    </div>
  );
}

export default MainV2;
