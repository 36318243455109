import React from 'react';
import Header from './Header';


const ParaPoints = ({ item }) => {

    return (
        <div className='mt-16' key={item?.id} >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barColor={item?.barColor} position={item?.titlePosition} />
            <div className='mt-12 font-semibold fontSize-16 lineHeight-30' >
                <div style={{color: item?.paraColor}} >{item?.paragraph}</div>
                <ul className='ml-4 mt-2' style={{color: item?.valueColor, listStyleType: "disc" }}>
                    {item?.values?.map((value) => {
                        return (
                            <li className='mt-1'>{value}</li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
};

export default ParaPoints;