import './App.css';
import { PortfolioContextProvider } from "./Components/context";
import Main from './Components/Main';


const App = () => {
  return (
    <div className="App">
      <PortfolioContextProvider>
        <Main />
      </PortfolioContextProvider>
    </div>
  );
}

export default App;
