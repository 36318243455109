import React from 'react';
import ProjectCard from './ProjectCard';
import { PortfolioData } from '../context';

const ProjectsOnHome = () => {

  const { projects } = PortfolioData();
  return (
    <div className='homeV2Projects_main my-20' >
        <div className='homeV2Projects_main_header flex items-center justify-center mb-20'>
            Featured Work
        </div>
        {projects?.map((project, index) => {
            return <ProjectCard key={project.title} project={project} index={JSON.stringify(index)} />
        })}
    </div>
  );
};

export default ProjectsOnHome;
