import React from 'react';


const Button = props => {

    return (
        <button className={"lp_button flex justify-center items-center px-8 py-3 rounded-md " + props.className} onClick={props.handleClick} >
            <span>{props.title}</span>
        </button>
    )
}

export default Button;