import React from 'react';
import Header from './Header';


const CompetitiveAnalysis = ({ item }) => {

    return (
        <div className='mt-16' key={item?.id} >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barColor={item?.barColor} position={item?.titlePosition} />
            <div className='mt-20' >
                {item?.values?.map((data) => {
                    return (
                        <div key={data?.id} className='mb-10 flex items-center justify-center' >
                            <div style={{ height: "55px", width: "152px"}} >
                                <img src={data?.imageUrl} alt="companyImage" />
                            </div>
                            <ul style={{color: data?.valueColor, listStyleType: "disc" }} className='w-1/2 ml-20'  >
                                {data?.values?.map((text, i) => {
                                    return (
                                        <li key={i} >{text}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default CompetitiveAnalysis;