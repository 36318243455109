import React from 'react';
import Header from './Header';


const TitleValue = ({item}) => {

    return (
        <div key={item?.id} className='py-10' >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barColor={item?.barColor} />
            <div className='titleValueValue' style={{ color: item?.valueColor }} >{item?.value}</div>
        </div>
    )
};

export default TitleValue;