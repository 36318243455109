import React from 'react';
import Header from './Header';


const TitleValues = ({item}) => {

    return (
        <div key={item?.id} className={`py-14 ${item?.position === "CENTER" ? "flex flex-col items-center" : ""}`} >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barType={item?.barType} />
            {item?.listType === "UNORDERED" 
            ? 
                <ul style={{ color: item?.valueColor, listStyleType: "disc" }} className='ml-4' >
                    {item?.values?.map((eachItem) => {
                        return (
                            <li className='titleValueValue' >{eachItem}</li>
                        )
                    })}
                </ul>
            :
                <ol style={{ color: item?.valueColor }} className='ml-4' >
                    {item?.values?.map((eachItem) => {
                        return (
                            <li className='titleValueValue' >{eachItem}</li>
                        )
                    })}
                </ol>
            }
        </div>
    )
};

export default TitleValues;