import React from 'react';
import Icon from '../Icons';

const Footer = ({ className, version }) => {

    const handleIconClick = () => {
        window.open("http://www.linkedin.com/in/leinasingha026");
    }

    return (
        <div className={className} id="contact" >
            <div className='lp_footer_header'>
                Let's Connect
            </div>
            <div className='lp_footer_content'>
                Get in touch for oppurtunities or just say hi!
            </div>
            <div className='lp_footer_buttons'>
                <div className='cursor-pointer' onClick={() => handleIconClick()}>
                    <Icon iconType="linkedInIcon" height="39px" width="39px" fill={version === "1" ? "#333333" : "#FFFFFF"} />
                </div>
                <div className='cursor-pointer'>
                    <a href='mailto:leinasingha08@gmail.com' ><Icon iconType="mailIcon" height="39px" width="39px" fill={version === "1" ? "#333333" : "#FFFFFF"} /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;