import React from 'react';


const SubHeading = ({item}) => {

    return (
        <div key={item?.id} className='flex items-center' >
            <div className='subHeadingTitle' style={{ color: item?.titleColor }} >{item?.title}</div>
            <div className='subHeadingValue ml-20 w-2/3' style={{ color: item?.valueColor }} >{item?.value}</div>
        </div>
    )
};

export default SubHeading;