import React from 'react';
import Icon from '../Icons';
import { PortfolioData } from '../context';

const Header = () => {
  const { aboutDetails } = PortfolioData();
  return (
    <div className='homeV2Header' >
        <div className='homeV2Header_content' >
            <div className='homeV2Header_content_designation' >{aboutDetails?.designation}</div>
            <div className='homeV2Header_content_greetings'>
                {aboutDetails?.greeting}
                <span className='homeV2Header_content_greetings_name' >{aboutDetails?.name}</span>
                <Icon className="homeV2Header_content_greetings_name_icon" iconType="doubleStarName" height="31px" width="31px" />
            </div>
            <div className='homeV2Header_bar'></div>
            <div className='homeV2Header_info'>
                {aboutDetails?.info}
            </div>
        </div>
    </div>
  );
};

export default Header;
