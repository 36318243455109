import React, { useEffect, useState } from 'react';
import CardTopLeft from './CardTopLeft';
import CardBottomRight from './CardBottomRight';
import Icon from '../Icons';
import { useNavigate } from "react-router-dom";
import Loader from '../Blocks/Loader';

const ProjectCard = ({project, index}) => {
  const navigate = useNavigate();

  const [projectNumber, setProjectNumber] = useState("");
  const [imageLoader, setImageLoader] = useState(true);

  useEffect(() => {
    if(index) {
      const count = "0" + (parseInt(index) + 1);
      setProjectNumber(count);
    }
  },[index])

  const handleLoader = () => {
    setImageLoader(false);
} 

  return (
    <div className='projectCard w-full' style={{ backgroundColor: project?.bgColor }} >
      <CardTopLeft color={project?.color} />
      <div className='flex projectCard_content justify-between'>
        <div className='w-1/2 flex flex-col'>
          <div className='flex mb-6' >
            <div className='projectCard_content_number'>{projectNumber}</div>
            <div className='projectCard_content_number_bar' ></div>
          </div>
          <div className='projectCard_content_title mb-6' >{project?.title}</div>
          <div className='projectCard_content_info mb-5' >{project?.projectInfo}</div>
          <button className="projectCard_content_button" onClick={() => navigate("/project/"+ project.projectPortfolioId)} >
            <div className='mr-2' >View Project</div>
            <Icon iconType="projectCardArrow" width="16px" height="16px" />
          </button>
        </div>
        {imageLoader && 
          <Loader /> 
        }
        <div className='projectCard_image' >
          <img src={project?.projectImageSrc} style={{height: "315px", width: "445px"}} alt="projectImage" onLoad={handleLoader} />
        </div>
      </div>
      <CardBottomRight color={project?.color} />
    </div>
  );
};

export default ProjectCard;
