import React from 'react';
import ProjectsOnHome from './ProjectsOnHome';
import Header from './Header';
import { PortfolioData } from '../context';
import Loader from '../Blocks/Loader';
import AnimationTriangular from '../Blocks/AnimationTriangular';

const Home = () => {
  const { dataLoader, error } = PortfolioData();
  return (
    dataLoader ?  <Loader />
        :
        (error === "" ?
          <div>
              <Header />
              <ProjectsOnHome />
              <AnimationTriangular />
          </div>
        : 
          <div className='error_message'>Please try again.</div>
        )
  );
};

export default Home;
