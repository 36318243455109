import React from 'react';
import { POSITION_TYPE } from './constants';

const Header = ({title, titleColor, bar, barColor, barType = "HORIZONTAL", position=POSITION_TYPE.LEFT}) => {

    return (
        <div className={`mb-10 flex items-center ${position === "CENTER" ? "justify-center" : ""} ${barType === "HORIZONTAL" ? "flex-row" : "flex-col"}`} >
            <div className='titleValueTitle' style={{ color: titleColor }} >{title}</div>
            {bar && <div style={{backgroundColor: barColor}} className={barType === "HORIZONTAL" ? "titleValueBarHorizontal" : "titleValueBarVertical"} ></div>}
        </div>
    )
}

export default Header;