import React from "react";


const Icon = ({ iconType, width = 24, height = 24, fill = "black", className, onClick, id }) => {
  const icons = {
    star: (
      <path 
        d="M7.51512 0C10.5052 0 13.4953 0 16.4853 0C16.4802 0.0159544 16.4734 0.03151 16.4702 0.0478633C16.3094 0.871112 16.1486 1.69476 15.9882 2.51801C15.6706 4.14536 15.3526 5.77232 15.035 7.39967C15.0166 7.4938 15.0015 7.58833 14.9819 7.69921C17.2686 6.58839 19.5337 5.48793 21.8071 4.38308C22.5397 6.95534 23.2683 9.51443 24.0004 12.0847C21.5362 12.3559 19.084 12.6263 16.6062 12.8992C18.4739 14.7862 20.3245 16.6564 22.183 18.5343C19.9434 20.1445 17.7142 21.7479 15.4779 23.3557C14.3072 20.9538 13.1426 18.5638 11.9687 16.1551C10.816 18.5634 9.67129 20.9546 8.52218 23.3557C6.26346 21.7463 4.01153 20.1421 1.75481 18.5343C3.61693 16.6524 5.46707 14.7826 7.33118 12.8992C7.11572 12.8752 6.92779 12.8541 6.73987 12.8334C6.0504 12.7564 5.36094 12.6798 4.67147 12.6028C3.98839 12.527 3.30571 12.4512 2.62263 12.3755C1.98902 12.3053 1.35582 12.2339 0.722211 12.1653C0.482015 12.1393 0.241022 12.119 0.000427246 12.0963C0.000427246 12.0831 0.000427246 12.0695 0.000427246 12.0564C0.00800817 12.0384 0.0171851 12.0209 0.022372 12.0025C0.686301 9.60298 1.35023 7.20383 2.01376 4.80428C2.05206 4.66588 2.09196 4.52747 2.13226 4.38388C4.43128 5.49072 6.71633 6.59078 9.01374 7.69682C9.00975 7.65335 9.00935 7.62423 9.00377 7.59591C8.89644 7.04588 8.78871 6.49585 8.68098 5.94582C8.36538 4.33123 8.05017 2.71664 7.73416 1.10245C7.66234 0.734702 7.58813 0.367351 7.51512 0Z" 
        fill={fill}
      />
    ),
    teamIcon: (
      <>
        <g clip-path="url(#clip0_1781_1974)">
          <path d="M40 15.4464C39.7467 16.2265 39.4344 16.9681 38.7972 17.5317C38.1671 18.0891 37.4413 18.3798 36.6045 18.3798C33.2126 18.3834 29.8217 18.3933 26.4298 18.3772C23.9552 18.3655 22.3506 16.0538 23.1963 13.7225C23.7682 12.1471 24.9325 11.0503 26.2571 10.102C26.5193 9.9141 26.8021 9.75397 27.0545 9.59473C29.1988 11.4108 31.5489 11.7481 34.0861 10.7515C34.6401 10.5341 35.1108 10.1082 35.629 9.79333C35.7454 9.72266 35.9449 9.64214 36.0335 9.69313C37.734 10.6665 39.0173 12.0308 39.8165 13.8307C39.8935 14.0043 39.9391 14.193 40 14.3747V15.4464Z" fill={fill}/>
          <path d="M14.2808 39.9999C13.1862 39.6913 12.3271 39.0829 11.8295 38.0371C11.3158 36.9582 11.3274 35.8963 11.9297 34.8407C12.7504 33.4021 13.8557 32.2499 15.2724 31.3892C15.5149 31.2416 15.6599 31.2506 15.8801 31.4438C18.121 33.4021 21.7939 33.4227 24.051 31.4823C24.3168 31.2542 24.4877 31.2488 24.7714 31.4241C26.2508 32.3384 27.399 33.5596 28.1848 35.1037C29.0788 36.8616 28.0693 39.1867 26.1613 39.8335C26.01 39.8845 25.8606 39.9435 25.7111 39.999H14.2808V39.9999Z" fill={fill}/>
          <path d="M12.9017 9.58203C14.3747 10.4579 15.6223 11.5126 16.4555 12.9959C17.4194 14.7109 17.0963 16.5922 15.6617 17.6846C15.0343 18.1623 14.3193 18.3788 13.5398 18.3797C10.1783 18.3832 6.81596 18.394 3.45451 18.377C0.984436 18.3645 -0.622903 16.034 0.231779 13.7134C0.810815 12.1415 1.97605 11.0465 3.297 10.0938C3.32116 10.0768 3.34622 10.0606 3.37128 10.0437C3.82234 9.73769 4.26355 9.74903 4.69492 10.0776C6.96631 11.8078 10.5309 11.6736 12.6716 9.77885C12.7379 9.7207 12.805 9.66434 12.9017 9.58203Z" fill={fill}/>
          <path d="M31.4684 2.80868e-07C34.2723 -0.000894334 36.4193 2.13544 36.4273 4.93469C36.4354 7.65074 34.1702 9.90786 31.4523 9.89265C28.745 9.87744 26.5103 7.63643 26.5157 4.94096C26.5202 2.14439 28.669 0.000894895 31.4684 2.80868e-07Z" fill={fill}/>
          <path d="M24.9101 26.5308C24.9164 29.3461 22.7891 31.4986 19.987 31.5129C17.2932 31.5263 15.0513 29.2916 15.0289 26.5701C15.0066 23.8648 17.2368 21.6113 19.9485 21.5997C22.7596 21.588 24.9048 23.7199 24.911 26.5317L24.9101 26.5308Z" fill={fill}/>
          <path d="M3.55474 4.96425C3.54669 2.16321 5.68205 0.010769 8.47789 3.36213e-05C11.3024 -0.00980714 13.4682 2.14174 13.4664 4.9553C13.4646 7.64541 11.2111 9.89 8.5101 9.89268C5.80466 9.89626 3.56369 7.66688 3.55564 4.96425H3.55474Z" fill={fill}/>
          <path d="M31.8863 19.8105C32.0716 19.8105 32.2327 19.8105 32.3938 19.8105C32.6891 19.8105 32.9853 19.8105 33.2708 19.8105C34.2401 23.8962 32.8287 29.5546 28.5141 32.9166C28.2215 32.5695 27.9288 32.2215 27.6317 31.8681C31.2831 28.6457 32.7124 24.6629 31.8863 19.8096V19.8105Z" fill={fill}/>
          <path d="M8.09038 19.8105C7.26524 24.6539 8.68195 28.6502 12.3539 31.8672C12.0595 32.2161 11.774 32.5534 11.4617 32.922C10.4566 32.1428 9.60107 31.2267 8.886 30.1862C6.80612 27.1615 6.04004 23.8309 6.59133 20.1961C6.63608 19.9 6.73274 19.7828 7.03702 19.8042C7.37531 19.8284 7.71719 19.8096 8.09128 19.8096L8.09038 19.8105Z" fill={fill}/>
          <path d="M24.5647 9.62643C24.1656 10.0032 23.8452 10.3281 23.497 10.6189C23.4084 10.6932 23.2205 10.704 23.097 10.6726C21.0252 10.1437 18.9578 10.1473 16.8833 10.6637C16.7374 10.7004 16.5128 10.6279 16.3929 10.5259C16.0895 10.2681 15.8246 9.96473 15.5453 9.67834C15.5382 9.67029 15.5427 9.6506 15.5373 9.58616C18.505 8.59813 21.4691 8.59813 24.5647 9.62643Z" fill={fill}/>
        </g>
        <defs>
          <clipPath id="clip0_1781_1974">
          <rect width="40" height="40" fill={fill}/>
          </clipPath>
        </defs>
      </>
    ),
    responsibilityIcon: (
      <>
        <path d="M25.4145 31C27.0603 31.8953 28.4541 32.9735 29.385 34.4897C30.4619 36.2428 30.1009 38.166 28.4981 39.2826C27.7972 39.771 26.9983 39.9923 26.1274 39.9932C22.372 39.9969 18.6155 40.0078 14.86 39.9905C12.1003 39.9777 10.3046 37.5954 11.2594 35.2232C11.9064 33.6164 13.2082 32.497 14.684 31.5231C14.711 31.5057 14.739 31.4893 14.767 31.4719C15.2709 31.1591 15.7638 31.1707 16.2458 31.5066C18.7834 33.2753 22.7659 33.1381 25.1576 31.2012C25.2316 31.1417 25.3066 31.0841 25.4145 31Z" fill={fill}/>
        <path d="M15.0006 25.5199C14.9917 22.4053 17.3615 20.011 20.4644 20C23.599 19.9891 26.0026 22.3815 26.0006 25.51C25.9986 28.5012 23.4977 30.997 20.5001 31C17.4976 31.004 15.0096 28.5251 15.0006 25.5199Z" fill={fill}/>
        <path d="M35.7212 19.0353C33.3644 19.0258 31.4873 17.1254 31.4856 14.7448C31.4838 12.353 33.3858 10.4388 35.7589 10.4448C38.1132 10.4509 40.0169 12.398 39.9998 14.7838C39.9835 17.1419 38.0687 19.0448 35.7212 19.0353ZM35.2809 16.884C35.9251 16.2263 36.5343 15.6024 37.1451 14.9802C37.5564 14.5614 37.9719 14.146 38.3823 13.7263C38.6007 13.503 38.6676 13.2426 38.4457 12.9985C38.2066 12.7355 37.9333 12.8047 37.6883 13.0158C37.5804 13.1084 37.4853 13.2175 37.3859 13.3204C36.6859 14.0361 35.986 14.7518 35.2578 15.496C34.7591 14.9837 34.2922 14.5017 33.8236 14.0214C33.5931 13.7843 33.3267 13.7073 33.0705 13.9487C32.8032 14.2005 32.8957 14.4696 33.1236 14.7016C33.8262 15.4164 34.5321 16.1268 35.2809 16.8831V16.884Z" fill={fill}/>
        <path d="M24.1862 4.30087C24.1888 6.64427 22.292 8.58442 19.9908 8.59134C17.5834 8.59826 15.666 6.6936 15.6694 4.29913C15.6737 1.90035 17.5791 0.00519929 19.9916 7.10613e-06C22.2594 -0.00431971 24.1836 1.96784 24.1862 4.30087ZM18.01 5.44747C17.9209 5.56776 17.8592 5.68458 17.7676 5.76852C17.4865 6.02467 17.6416 6.19774 17.8412 6.4063C18.0486 6.6235 18.2096 6.72561 18.4795 6.47985C18.8565 6.1363 19.3756 6.33534 19.4091 6.84677C19.4348 7.24829 19.6472 7.25003 19.9454 7.26387C20.2769 7.27945 20.3883 7.16089 20.4174 6.83205C20.4603 6.33966 20.9674 6.12765 21.3187 6.45995C21.6229 6.74811 21.7908 6.59148 22.0238 6.37687C22.2791 6.14236 22.2457 5.96323 22.0461 5.72006C21.9595 5.61362 21.8987 5.41199 21.939 5.29084C22.0058 5.08575 22.0187 4.78893 22.3828 4.82095C22.6981 4.84864 22.8745 4.71364 22.8668 4.33894C22.86 3.98501 22.764 3.7704 22.3905 3.79722C22.0881 3.81886 22.0281 3.60598 21.9184 3.40002C21.7993 3.17503 21.8619 3.02186 22.0238 2.87215C22.3022 2.61514 22.1857 2.42216 21.9587 2.19284C21.7351 1.96698 21.5706 1.91246 21.3161 2.14871C21.2082 2.24909 20.9923 2.30447 20.8484 2.27332C20.6248 2.22572 20.3712 2.13919 20.396 1.80602C20.42 1.47978 20.2795 1.3543 19.9394 1.35776C19.6052 1.36122 19.4202 1.41142 19.3911 1.80775C19.3559 2.28803 18.8359 2.4836 18.4821 2.15736C18.1993 1.89688 18.0349 2.01025 17.8121 2.23265C17.5911 2.45331 17.5208 2.61946 17.7573 2.8808C17.8549 2.98811 17.9098 3.20705 17.8764 3.3481C17.825 3.56358 17.7753 3.83616 17.424 3.80588C17.0882 3.77732 16.9614 3.95126 16.9639 4.30692C16.9665 4.65134 17.0702 4.84172 17.4154 4.81749C17.8892 4.7846 17.8027 5.2294 18.0083 5.44747H18.01Z" fill={fill}/>
        <path d="M4.23803 19.0335C1.87773 19.0214 -0.0190764 17.0769 0.000628465 14.6894C0.0203333 12.346 1.96854 10.4223 4.29971 10.443C6.63517 10.4638 8.56196 12.4221 8.54054 14.7525C8.51827 17.1453 6.60947 19.0456 4.23803 19.0335ZM5.58995 12.3157C5.55226 12.841 5.26097 13.0158 4.81033 13.0062C4.41109 12.9976 4.01185 13.0062 3.61261 13.0045C3.29734 13.0028 3.05317 12.8929 2.97435 12.5528C2.88011 12.1487 2.55883 12.32 2.34551 12.3408C2.23584 12.3512 2.06278 12.5779 2.06107 12.7094C2.04051 14.1918 2.04993 15.675 2.04651 17.1583C2.04651 17.4239 2.14503 17.5581 2.42347 17.5563C3.64945 17.5511 4.87544 17.5633 6.10142 17.5399C6.22222 17.5373 6.4424 17.3434 6.44412 17.2353C6.46639 15.7096 6.46639 14.1831 6.44412 12.6575C6.4424 12.5424 6.24278 12.3884 6.10399 12.3287C5.97206 12.2724 5.79728 12.3166 5.59081 12.3166L5.58995 12.3157ZM4.28087 11.9254C4.06668 11.9254 3.85336 11.9297 3.63917 11.9245C3.39929 11.9185 3.26135 12.0145 3.25107 12.2716C3.24079 12.5329 3.34617 12.6887 3.6169 12.6913C4.05897 12.6947 4.50362 12.7215 4.94055 12.6748C5.06906 12.661 5.24126 12.4455 5.27382 12.2949C5.32265 12.0734 5.17786 11.9185 4.9217 11.9254C4.70838 11.9306 4.49419 11.9263 4.28001 11.9254H4.28087Z" fill={fill}/>
        <path d="M14.6482 19.5401C14.655 19.8897 14.4289 20.0394 14.1658 19.9365C13.9817 19.8638 13.812 19.7513 13.6372 19.6561C12.1465 18.8426 10.6558 18.0292 9.16596 17.2158C9.07857 17.1682 8.95948 17.1379 8.91322 17.0626C8.83954 16.9423 8.74359 16.7589 8.78814 16.6637C8.83183 16.5693 9.03316 16.5261 9.17195 16.5088C9.25677 16.4984 9.35529 16.5754 9.44268 16.623C11.0739 17.5065 12.7051 18.3901 14.3329 19.2805C14.4683 19.3549 14.5771 19.4796 14.649 19.5401H14.6482Z" fill={fill}/>
        <path d="M25.6821 19.9984C25.5835 19.9162 25.437 19.8487 25.3933 19.7388C25.3582 19.6523 25.4362 19.506 25.491 19.4013C25.5193 19.3468 25.6049 19.32 25.6666 19.2854C27.3261 18.3655 28.9865 17.4456 30.6468 16.5266C30.7205 16.4859 30.8087 16.421 30.879 16.4331C31.0058 16.4565 31.1831 16.4989 31.2277 16.5898C31.2731 16.6815 31.2097 16.8589 31.1429 16.9662C31.088 17.0536 30.9604 17.0969 30.861 17.1514C29.2503 18.0427 27.6388 18.9323 26.0273 19.8219C25.9279 19.8764 25.826 19.9249 25.6821 19.9976V19.9984Z" fill={fill}/>
        <path d="M20.2512 12.6595C20.2512 13.6529 20.2512 14.6463 20.2512 15.6398C20.2512 15.8812 20.2349 16.1425 19.918 16.1313C19.607 16.1209 19.6018 15.8596 19.6018 15.619C19.6027 13.9636 19.6018 12.3081 19.6018 10.6518C19.6018 10.3636 19.601 10.0763 19.6018 9.78818C19.6027 9.54242 19.5718 9.23867 19.9145 9.23348C20.2906 9.22742 20.2495 9.55194 20.2504 9.80808C20.2529 10.7583 20.2512 11.7084 20.2512 12.6586V12.6595Z" fill={fill}/>
        <path d="M19.9334 5.96738C19.0132 5.97171 18.261 5.22404 18.2619 4.30415C18.2619 3.39293 19.0312 2.62362 19.9342 2.63141C20.8304 2.63919 21.5689 3.37995 21.5792 4.28165C21.5894 5.20586 20.8509 5.96392 19.9334 5.96825V5.96738Z" fill={fill}/>
        <path d="M4.78805 16.0732C4.98767 16.0732 5.189 16.0585 5.38605 16.0802C5.46401 16.0888 5.53255 16.1797 5.60537 16.2333C5.53941 16.2956 5.47601 16.409 5.40747 16.4116C4.97996 16.4272 4.55159 16.4263 4.12494 16.4099C4.05897 16.4073 3.99729 16.293 3.93389 16.2307C4.005 16.1788 4.07182 16.0897 4.14636 16.0828C4.35797 16.062 4.57387 16.0758 4.78719 16.0758C4.78719 16.0758 4.78719 16.075 4.78719 16.0741L4.78805 16.0732Z" fill={fill}/>
        <path d="M4.77349 15.3005C4.57473 15.3005 4.37339 15.3178 4.1772 15.2927C4.09153 15.2814 4.01699 15.1845 3.93732 15.1265C4.00671 15.0712 4.07439 14.9699 4.1455 14.9673C4.55759 14.9517 4.97054 14.9509 5.38262 14.9656C5.45973 14.9682 5.53341 15.0634 5.6088 15.1153C5.52998 15.1767 5.4563 15.2806 5.37063 15.2918C5.17529 15.3187 4.97311 15.3005 4.77434 15.3005H4.77349Z" fill={fill}/>
        <path d="M5.60109 14.1288H3.82765C3.98786 13.9955 4.07611 13.8596 4.15835 13.864C4.64326 13.8891 5.15902 13.7255 5.61309 13.9704L5.60109 14.1288Z" fill={fill}/>
      </>
    ),
    timelineIcon: (
      <>
        <path d="M5.50004 14.4811C5.50794 6.48438 12.0031 -0.0138165 19.9748 2.2062e-05C28.0513 0.0148491 34.5227 6.51304 34.5 14.5869C34.4782 22.5895 27.9722 29.0294 19.9235 29.0165C11.9537 29.0027 5.49214 22.4916 5.50004 14.4811ZM18.4231 10.714C18.4231 11.913 18.438 13.1111 18.4182 14.3091C18.4054 15.1048 18.7372 15.6445 19.4503 15.9954C21.2006 16.8564 22.9379 17.7401 24.6852 18.606C24.9025 18.7137 25.1405 18.8096 25.3775 18.8392C26.1262 18.9332 26.8127 18.4686 27.0458 17.746C27.2828 17.0126 26.9786 16.2495 26.2517 15.8768C24.8234 15.1434 23.3854 14.4267 21.9433 13.721C21.6717 13.5875 21.568 13.4333 21.5709 13.1239C21.5858 11.1045 21.5818 9.08504 21.5769 7.0656C21.5749 6.06922 20.9042 5.34962 19.9985 5.35159C19.0879 5.35357 18.4291 6.06626 18.4251 7.06856C18.4202 8.28339 18.4241 9.49822 18.4241 10.713L18.4231 10.714Z" fill="white"/>
        <path d="M18.4231 10.7135C18.4231 9.49868 18.4192 8.28385 18.4241 7.06902C18.4281 6.06672 19.0869 5.35403 19.9975 5.35205C20.9032 5.35008 21.5729 6.06968 21.5759 7.06606C21.5808 9.0855 21.5848 11.1049 21.5699 13.1244C21.568 13.4338 21.6707 13.588 21.9423 13.7214C23.3844 14.4282 24.8224 15.1438 26.2507 15.8773C26.9776 16.2509 27.2818 17.014 27.0448 17.7465C26.8117 18.468 26.1252 18.9326 25.3766 18.8397C25.1395 18.8101 24.9015 18.7142 24.6842 18.6064C22.9369 17.7405 21.1986 16.8559 19.4493 15.9959C18.7362 15.645 18.4044 15.1053 18.4172 14.3096C18.437 13.1115 18.4221 11.9125 18.4221 10.7145L18.4231 10.7135Z" fill="#0968BD"/>
        <path d="M40.0001 36.3339C39.6852 37.1017 39.1174 37.4042 38.2915 37.3541C37.5441 37.309 36.7916 37.334 36.0426 37.3549C35.9165 37.3583 35.7319 37.4694 35.6785 37.5805C34.911 39.1773 33.6575 40.0438 31.8821 39.9961C30.1594 39.9502 28.9735 39.0453 28.2862 37.4744C28.2695 37.4368 28.247 37.4009 28.2203 37.3507H23.7818C23.0536 39.0269 21.8218 40.0137 19.9621 39.9987C18.1399 39.9836 16.934 39.0018 16.2175 37.3499H11.7807C11.085 38.9667 9.90924 39.9485 8.12465 39.9978C6.22649 40.0505 4.96801 39.0611 4.20975 37.3457C3.29699 37.3457 2.3809 37.3491 1.4648 37.3449C0.607166 37.3415 0.00840606 36.8009 5.51523e-05 36.0331C-0.00829575 35.2426 0.59464 34.6844 1.47315 34.6794C2.29238 34.6744 3.11244 34.6744 3.93166 34.6811C4.12874 34.6828 4.24816 34.6468 4.34503 34.438C5.0749 32.8596 6.33004 32.0039 8.05868 32.0324C9.78648 32.0599 11.0116 32.9515 11.7013 34.5508C11.7231 34.6009 11.7523 34.6468 11.7782 34.6945H16.2158C16.9482 32.9682 18.2409 31.9663 20.1625 32.0349C21.9162 32.0975 23.1003 33.0676 23.7742 34.6953H28.2311C28.581 33.7812 29.1606 33.0426 30.0274 32.5471C32.0517 31.3906 34.6095 32.1961 35.5958 34.321C35.7302 34.6101 35.8806 34.6978 36.1854 34.687C36.8651 34.6636 37.5482 34.712 38.2263 34.6711C39.0514 34.6209 39.656 34.8967 40.0001 35.6696V36.3364V36.3339Z" fill="white"/>
      </>
    ),
    timelineGreenIcon: (
      <>
        <path d="M5.50001 14.4728C5.50791 6.48068 12.003 -0.0138086 19.9748 2.20494e-05C28.0512 0.0148406 34.5227 6.50933 34.4999 14.5785C34.4782 22.5766 27.9722 29.0128 19.9234 29C11.9537 28.9861 5.49211 22.4788 5.50001 14.4728ZM18.4231 10.7079C18.4231 11.9063 18.4379 13.1036 18.4182 14.3009C18.4053 15.0962 18.7372 15.6356 19.4503 15.9863C21.2005 16.8468 22.9379 17.73 24.6851 18.5954C24.9024 18.703 25.1405 18.7989 25.3775 18.8285C26.1262 18.9224 26.8127 18.458 27.0457 17.7359C27.2828 17.0029 26.9786 16.2402 26.2516 15.8678C24.8234 15.1347 23.3853 14.4185 21.9433 13.7131C21.6717 13.5798 21.568 13.4257 21.5709 13.1164C21.5857 11.0982 21.5818 9.07986 21.5768 7.06157C21.5749 6.06576 20.9042 5.34657 19.9985 5.34854C19.0878 5.35052 18.429 6.0628 18.4251 7.06453C18.4201 8.27867 18.4241 9.4928 18.4241 10.7069L18.4231 10.7079Z" fill="#388259"/>
        <path d="M18.4232 10.71C18.4232 9.49583 18.4192 8.28169 18.4241 7.06756C18.4281 6.06582 19.0869 5.35354 19.9976 5.35157C20.9033 5.34959 21.5729 6.06879 21.5759 7.06459C21.5808 9.08288 21.5848 11.1012 21.57 13.1195C21.568 13.4287 21.6707 13.5828 21.9423 13.7162C23.3844 14.4225 24.8225 15.1378 26.2507 15.8708C26.9776 16.2442 27.2819 17.0069 27.0448 17.7389C26.8117 18.4601 26.1253 18.9244 25.3766 18.8315C25.1395 18.8019 24.9015 18.7061 24.6842 18.5984C22.937 17.733 21.1986 16.8488 19.4494 15.9893C18.7363 15.6386 18.4044 15.0992 18.4172 14.304C18.437 13.1066 18.4222 11.9083 18.4222 10.711L18.4232 10.71Z" fill="white"/>
        <path d="M40.0001 36.3133C39.6853 37.0808 39.1174 37.3831 38.2915 37.333C37.5441 37.2879 36.7917 37.3129 36.0426 37.3338C35.9165 37.3371 35.7319 37.4482 35.6785 37.5593C34.911 39.1551 33.6576 40.0211 31.8822 39.9735C30.1594 39.9276 28.9736 39.0232 28.2863 37.4532C28.2696 37.4156 28.247 37.3797 28.2203 37.3296H23.7818C23.0536 39.0048 21.8218 39.9911 19.9621 39.976C18.1399 39.961 16.9341 38.9798 16.2175 37.3288H11.7807C11.0851 38.9447 9.90927 39.9259 8.12468 39.9752C6.22652 40.0278 4.96804 39.0391 4.20978 37.3246C3.29702 37.3246 2.38093 37.328 1.46483 37.3238C0.607197 37.3204 0.00843658 36.7801 8.56699e-05 36.0127C-0.00826524 35.2227 0.59467 34.6649 1.47319 34.6598C2.29241 34.6548 3.11247 34.6548 3.93169 34.6615C4.12877 34.6632 4.24819 34.6273 4.34506 34.4185C5.07493 32.841 6.33007 31.9859 8.05871 32.0143C9.78651 32.0418 11.0116 32.9329 11.7014 34.5312C11.7231 34.5814 11.7523 34.6273 11.7782 34.6749H16.2159C16.9482 32.9496 18.241 31.9483 20.1625 32.0168C21.9162 32.0794 23.1004 33.049 23.7743 34.6757H28.2312C28.5811 33.7621 29.1606 33.0239 30.0274 32.5287C32.0517 31.3729 34.6096 32.178 35.5958 34.3016C35.7303 34.5905 35.8806 34.6782 36.1854 34.6674C36.8652 34.644 37.5483 34.6924 38.2264 34.6515C39.0514 34.6014 39.656 34.877 40.0001 35.6494V36.3158V36.3133Z" fill="#388259"/>
      </>
    ),
    backArrow: (
      <path d="M19.0625 31.25L7.8125 20L19.0625 8.75M9.375 20H32.1875" stroke="#020016" stroke-width="3.75" stroke-linecap="round" stroke-linejoin="round"/>
    ),
    scrollUp: (
      <path d="M21.7658 5.47186C21.2971 5.00369 20.6617 4.74072 19.9992 4.74072C19.3367 4.74072 18.7012 5.00369 18.2325 5.47186L8.80083 14.8985C8.33184 15.3675 8.06836 16.0036 8.06836 16.6669C8.06836 17.3301 8.33184 17.9662 8.80083 18.4352C9.26982 18.9042 9.90591 19.1677 10.5692 19.1677C11.2324 19.1677 11.8685 18.9042 12.3375 18.4352L17.4992 13.2752V32.5002C17.4992 33.1632 17.7626 33.7991 18.2314 34.268C18.7002 34.7368 19.3361 35.0002 19.9992 35.0002C20.6622 35.0002 21.2981 34.7368 21.7669 34.268C22.2358 33.7991 22.4992 33.1632 22.4992 32.5002V13.2752L27.6592 18.4352C27.8914 18.6674 28.1671 18.8516 28.4705 18.9773C28.7739 19.103 29.0991 19.1677 29.4275 19.1677C29.7559 19.1677 30.0811 19.103 30.3845 18.9773C30.6879 18.8516 30.9636 18.6674 31.1958 18.4352C31.428 18.203 31.6123 17.9273 31.7379 17.6239C31.8636 17.3205 31.9283 16.9953 31.9283 16.6669C31.9283 16.3384 31.8636 16.0133 31.7379 15.7098C31.6123 15.4064 31.428 15.1307 31.1958 14.8985L21.7658 5.47186Z" fill="white"/>
    ),
    linkedInIcon: (
      <path d="M30.875 4.875C31.737 4.875 32.5636 5.21741 33.1731 5.8269C33.7826 6.4364 34.125 7.26305 34.125 8.125V30.875C34.125 31.737 33.7826 32.5636 33.1731 33.1731C32.5636 33.7826 31.737 34.125 30.875 34.125H8.125C7.26305 34.125 6.4364 33.7826 5.8269 33.1731C5.21741 32.5636 4.875 31.737 4.875 30.875V8.125C4.875 7.26305 5.21741 6.4364 5.8269 5.8269C6.4364 5.21741 7.26305 4.875 8.125 4.875H30.875ZM30.0625 30.0625V21.45C30.0625 20.045 29.5044 18.6976 28.5109 17.7041C27.5174 16.7106 26.17 16.1525 24.765 16.1525C23.3837 16.1525 21.775 16.9975 20.995 18.265V16.4613H16.4613V30.0625H20.995V22.0513C20.995 20.8 22.0025 19.7762 23.2537 19.7762C23.8571 19.7762 24.4358 20.0159 24.8624 20.4426C25.2891 20.8692 25.5287 21.4479 25.5287 22.0513V30.0625H30.0625ZM11.18 13.91C11.904 13.91 12.5984 13.6224 13.1104 13.1104C13.6224 12.5984 13.91 11.904 13.91 11.18C13.91 9.66875 12.6913 8.43375 11.18 8.43375C10.4516 8.43375 9.75313 8.72309 9.23811 9.23811C8.72309 9.75313 8.43375 10.4516 8.43375 11.18C8.43375 12.6913 9.66875 13.91 11.18 13.91ZM13.4388 30.0625V16.4613H8.9375V30.0625H13.4388Z" fill={fill}/>
    ),
    mailIcon: (
      <path d="M30.875 6.5H8.125C6.83207 6.5 5.59209 7.01361 4.67785 7.92785C3.76361 8.84209 3.25 10.0821 3.25 11.375V27.625C3.25 28.9179 3.76361 30.1579 4.67785 31.0721C5.59209 31.9864 6.83207 32.5 8.125 32.5H30.875C32.1679 32.5 33.4079 31.9864 34.3221 31.0721C35.2364 30.1579 35.75 28.9179 35.75 27.625V11.375C35.75 10.0821 35.2364 8.84209 34.3221 7.92785C33.4079 7.01361 32.1679 6.5 30.875 6.5ZM29.7862 9.75L19.5 17.4687L9.21375 9.75H29.7862ZM30.875 29.25H8.125C7.69402 29.25 7.2807 29.0788 6.97595 28.774C6.67121 28.4693 6.5 28.056 6.5 27.625V11.7812L18.525 20.8C18.8063 21.011 19.1484 21.125 19.5 21.125C19.8516 21.125 20.1937 21.011 20.475 20.8L32.5 11.7812V27.625C32.5 28.056 32.3288 28.4693 32.024 28.774C31.7193 29.0788 31.306 29.25 30.875 29.25Z" fill={fill}/>
    ),
    projectCardArrow: (
      <path d="M11.144 10.906L11.255 1.14883L1.50139 1.43673M10.1571 2.26684L0.744982 11.8512" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    ),
    doubleStarName: (
      <>
        <path d="M11.3647 0.423859C11.3465 0.472296 11.2496 0.962727 11.1406 1.5137C10.2143 6.20609 9.17891 8.22835 7.03555 9.52405C5.83672 10.2506 3.92344 10.8682 1.27149 11.3828C0.720509 11.4858 0.230079 11.5887 0.181642 11.6069C0.133204 11.625 0.599415 11.7461 1.22305 11.8672C5.09805 12.6483 6.98711 13.4172 8.31914 14.7492C9.57246 16.0026 10.402 18.0491 11.1104 21.5971C11.2254 22.1905 11.3404 22.7414 11.3586 22.8262C11.3828 22.9231 11.4857 22.5416 11.6553 21.7061C12.4484 17.8069 13.1508 16.0813 14.4949 14.7371C15.8391 13.3991 17.5102 12.7149 21.4275 11.8975C22.1117 11.7582 22.6324 11.625 22.584 11.6069C22.5355 11.5887 21.9906 11.4737 21.373 11.3526C17.7947 10.6442 15.7725 9.82073 14.4949 8.54319C13.1932 7.24749 12.4545 5.4553 11.6855 1.72562C11.5281 0.956671 11.3828 0.375422 11.3647 0.423859Z" fill="white"/>
        <path d="M23.7102 16.5293C23.0502 19.6414 22.7051 20.5557 21.8514 21.4639C21.0219 22.3358 19.9744 22.7838 17.498 23.3106C17.0197 23.4135 16.6201 23.5043 16.608 23.5164C16.5959 23.5286 16.9652 23.6073 17.4193 23.6981C19.6414 24.134 20.9311 24.6547 21.7484 25.4479C22.584 26.2653 23.0684 27.4036 23.5285 29.6317C23.6436 30.1948 23.7465 30.667 23.7525 30.6791C23.7646 30.6852 23.8736 30.1948 24.0008 29.5893C24.5094 27.1372 25.133 25.8414 26.1684 25.1088C27.0221 24.5034 28.0453 24.128 29.8314 23.7586C30.4369 23.6315 30.9273 23.5225 30.9213 23.5104C30.9092 23.5043 30.4369 23.4014 29.8738 23.2864C27.6457 22.8262 26.5074 22.3418 25.69 21.5063C24.9029 20.695 24.4125 19.5022 23.9766 17.3346C23.7525 16.2448 23.7646 16.269 23.7102 16.5293Z" fill="white"/>
      </>
    )
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
      data-testid={id}
    >
      {icons[iconType]}
    </svg>
  );
};

export default Icon;
