// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.FIREBASEAPIKEY,
  authDomain: "myportfolio-a3b8e.firebaseapp.com",
  projectId: "myportfolio-a3b8e",
  storageBucket: "myportfolio-a3b8e.appspot.com",
  messagingSenderId: process.env.FIREBASEMESSAGINGSENDERID,
  appId: process.env.FIREBASEAPPID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);