import React from 'react';
import Myimage from '../../Assets/myimage.png';

const About = () => {
    return (
        <div className='aboutV2' >
            <div className='flex flex-col' >
                <div className='aboutV2_title' >A LITTLE MORE</div>
                <div className='aboutV2_subTitle'>about me</div>
                <div>
                    <img src={Myimage} alt="myImage" />
                </div>
            </div>
            <div className='flex flex-col aboutV2_content' >
                <div className='aboutV2_info' >
                    Hi I'm Leina, previously worked at SamvidDhi (Korea Based). Prior to that I interned at Applore Technologies. I am currently in Bangalore after postgraduating  in Resource Management & Design Application from Maharani Cluster University.
                </div>
                <div className='aboutV2_info'>
                    Beyond my focus on user-centred design , I like to explore design content, practice and read about them. Besides that I like to watch movies and play badminton and always like to explore and learn new things around me
                </div>
            </div>
        </div>
    )
};

export default About;