import React from 'react';
import SubHeading from './SubHeading';

const KeyValue = ({ item }) => {

    return (
        <div>
            <SubHeading item={{ title: item?.title, titleColor: item?.titleColor }} />
            <div className='mx-40 flex flex-wrap' >
                {item?.values?.map((data) => {
                    return (
                        <div key={data?.id} className='w-1/2 mt-10' >
                            <div style={{color: data?.titleColor, fontSize: data?.fontSize, fontWeight: data?.fontWeight}} > {data?.title} </div>
                            <div style={{color: data?.valueColor, fontSize: data?.fontSize, fontWeight: data?.fontWeight}}> {data?.value} </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default KeyValue;