import React from 'react';
import Header from './Header';


const BannerPoints = ({ item }) => {

    return (
        <div className='mt-16' key={item?.id} >
            <Header title={item?.title} titleColor={item?.titleColor} bar={true} barColor={item?.barColor} position={item?.titlePosition} />
            <div className='flex justify-between mx-20' >
                <div className='w-1/3' >
                    {item?.leftValues?.map((data) => {
                        return (
                            <div key={data?.id} className='mb-16 flex flex-col' >
                                <div style={{ backgroundColor: data?.bgColor}} className='flex items-center justify-center p-8 w-2/3' >
                                    <div style={{ color: data?.titleColor}} className='fontSize-24 lineHeight-30 font-semibold' >{data?.title}</div>
                                </div>
                                <ul style={{color: data?.valueColor, listStyleType: "disc" }} className='mt-8 ml-4'  >
                                    {data?.values?.map((text, i) => {
                                        return (
                                            <li className='fontSize-18 lineHeight-30 font-semibold' key={i} >{text}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
                <div>
                    {item?.rightValues?.map((data) => {
                        return (
                            <div key={data?.id} className='mb-16 flex flex-col' >
                                <div style={{ backgroundColor: data?.bgColor}} className='flex items-center justify-center p-8 w-2/3' >
                                    <div style={{ color: data?.titleColor}} className='fontSize-24 lineHeight-30 font-semibold' >{data?.title}</div>
                                </div>
                                <ul style={{color: data?.valueColor, listStyleType: "disc" }} className='mt-8 ml-4'  >
                                    {data?.values?.map((text, i) => {
                                        return (
                                            <li className='fontSize-18 lineHeight-30 font-semibold' key={i} >{text}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

export default BannerPoints;